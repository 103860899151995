import styled, { css } from 'styled-components';

export const CardContainer = styled.div`
  perspective: 1000px;
`;

export const Card = styled.div<{ flipped: boolean }>`
  position: relative;
  transform-style: preserve-3d;
  transition: transform 0.6s;
  transform: ${({ flipped }) =>
    flipped ? 'rotateY(180deg)' : 'rotateY(0deg)'};
`;

export const CardFace = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background-color: transparent;
  z-index: 0;
`;

export const FrontFace = styled(CardFace)`
  background-color: transparent;
`;

export const BackFace = styled(CardFace)`
  background-size: cover;
  z-index: 100;
  transform: rotateY(180deg);
`;

export const WaitingPaymentContainer = styled.div`
  position: absolute;
  height: 100px;

  top: -15px;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: row;

  span {
    font-size: 12px;
    background-color: var(--primary);
    padding: 4px 20px;
    width: 28px;
    height: 28px;
    padding: 4px;

    margin-left: -54px;

    border-radius: 50px;

    transition: 0.6s ease;

    &.pending {
      background-color: var(--primary60);
      width: 220px;
      padding-left: 38px;
    }
  }
`;

export const CloseButton = styled.div`
  position: absolute;

  top: 20px;
  right: 20px;

  width: 30px;
  height: 30px;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;

  text-align: center;

  border-radius: 50%;

  font-size: 14px;
  font-weight: 600;

  cursor: pointer;
  background-color: #a12518;

  transition: 0.2s ease;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

  &:hover {
    color: #fff;
    transform: scale(1.2);
  }
`;

export const PriceContainer = styled.div`
  position: absolute;

  bottom: 186px;

  background-color: #fff;
  color: #000;

  width: 150px;

  text-align: center;

  padding: 10px 10px;
  border-radius: 18px 18px 0 0;

  font-size: 14px;
  font-weight: 600;
`;

export const CopyCodeContainer = styled.div`
  position: absolute;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 150px;
  bottom: 10px;
  gap: 10px;

  cursor: pointer;

  color: #000;
  font-size: 14px;
  font-weight: 500;
  background-color: #fff;

  text-align: center;

  padding: 6px 10px;
  border-radius: 18px;

  transition: 0.2s ease;

  &:hover {
    background-color: #a12518;
    color: #fff;
    transform: scale(1.1);
  }
`;

export const QrCodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  bottom: 50px;

  background-color: #fff;

  border-radius: 0 0 10px 10px;
`;

export const QrCode = styled.img`
  width: 150px;
  height: 150px;
`;

export const Container = styled.div`
  background-color: #fff;
  position: relative;

  width: 350px;
  padding-bottom: 140px;

  border-radius: 12px;

  background-size: cover;

  overflow: hidden;

  .backgroundShadow {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: #00000080;

    border-radius: 12px;

    z-index: 0;
  }

  .backgroundShadowGradient {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background: linear-gradient(to top, #00000090, #00000000);

    border-radius: 12px;

    z-index: 1;
  }

  .lineContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    gap: 6px;

    position: absolute;

    font-size: 20px;

    bottom: 98px;

    .line {
      width: 12px;
      border-radius: 20px;
      height: 3px;
      background-color: var(--background);
      z-index: 1;
    }
  }

  .roundLeft {
    position: absolute;
    left: -20px;
    bottom: 80px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: var(--background);

    z-index: 100;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.16);
  }

  .roundRight {
    position: absolute;
    right: -20px;
    bottom: 80px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: var(--background);

    z-index: 100;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.16);
  }
  .roundLeftSupport {
    position: absolute;
    left: -40px;
    bottom: 80px;

    border-radius: 2px;

    width: 40px;
    height: 40px;

    background-color: var(--background);

    z-index: 10;
  }

  .roundRightSupport {
    position: absolute;
    right: -40px;
    bottom: 80px;

    border-radius: 2px;

    width: 40px;
    height: 40px;

    background-color: var(--background);

    z-index: 10;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  flex: 1;
  padding: 40px 40px 20px;
`;

export const HeaderImg = styled.img`
  height: 85px;
  margin-left: -5px;
  z-index: 1;
`;

export const HeaderInfo = styled.div`
  width: 100%;
  height: 100%;
  z-index: 1;

  .title {
    display: block;

    text-align: center;
    font-size: 16px;
    font-weight: 500;

    color: #ffffff;
  }

  .value {
    display: block;

    text-align: center;
    font-size: 48px;
    font-weight: 700;

    color: #ffffff;
  }
`;

export const ListOfBeneficits = styled.div`
  position: relative;
  z-index: 10;
  font-size: 14px;

  color: #ffffff;

  padding: 0 40px;
  font-weight: 400;
`;

export const Beneficit = styled.div`
  font-size: 14px;
  z-index: 2020;

  &.highlight {
    font-weight: 700;
    background-color: #32bcad80;
    padding: 2px 8px;
    width: max-content;
    border-radius: 4px;
  }
`;

export const Footer = styled.footer`
  position: absolute;
  bottom: 20px;

  padding: 0 40px;

  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

export const ButtonBuy = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: relative;

  width: 100%;
  height: 56px;

  border: 2px solid #32bcad;

  background-color: #32bcad20;

  border-radius: 10px;

  transition: 0.6s ease;

  overflow: hidden;

  &::after {
    content: '';
    width: 0%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;

    background-color: #32bcad60;

    transition: 5s ease;

    ${props =>
      props.active &&
      css`
        width: 100%;
      `}
  }

  &:hover {
    transform: scale(1.05);
  }

  img {
    height: 30px;

    margin-right: 10px;
  }

  div {
    text-align: start;
    color: #32bcad;

    font-size: 14px;
  }
`;
