import React, { useCallback, useEffect, useState } from 'react';
import * as S from './styles';
import Coupon from '../../components/Coupon';
import background1 from '../../assets/backgroundcard1.png';
import background2 from '../../assets/backgroundcard2.png';
import background3 from '../../assets/backgroundcard3.png';
import background4 from '../../assets/backgroundcard4.png';
import background5 from '../../assets/backgroundcard5.png';
import background6 from '../../assets/backgroundcard6.png';
import background7 from '../../assets/backgroundcard7.png';
import IndicationBanner from '../../components/IndicationBanner';
import { IUser } from '../../DTOS/IUser';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { ICoupon } from '../../DTOS/ICoupon';

const backgrounds = [
  background5,
  background2,
  background3,
  background4,
  background1,
  background6,
  background7,
];

const Home: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();
  const [userFromApi, setUserFromApi] = useState<IUser>();
  const [coupons, setCoupons] = useState<ICoupon[]>([]);

  const getUser = useCallback(async () => {
    try {
      const response = await api.get<IUser>(`user/${user.id}`);
      setUserFromApi(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar usuário',
        type: 'error',
      });
    }
  }, [addToast, user.id]);

  const getCoupons = useCallback(async () => {
    try {
      const response = await api.get<ICoupon[]>(`product/coupons/all`);
      setCoupons(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar usuário',
        type: 'error',
      });
    }
  }, [addToast]);

  useEffect(() => {
    getUser();
    getCoupons();
  }, [getCoupons, getUser]);

  return (
    <S.Container>
      {userFromApi &&
        userFromApi.referral.indications < userFromApi.referral.limit && (
          <IndicationBanner
            code={userFromApi?.referral.code}
            remaining={
              userFromApi.referral.limit - userFromApi.referral.indications
            }
          />
        )}

      <S.SectionTitle>
        Escolha seu cupom
        <S.Description>
          Assim que adquirir seu cupom, um agente da nossa equipe fará o pedido
          com você.
        </S.Description>
      </S.SectionTitle>

      {coupons?.length > 0 &&
        coupons.map((c, i) => (
          <Coupon
            id={c.id}
            key={c.id}
            coupon={c.value}
            price={c.price}
            minOrder={c.minOrder}
            background={backgrounds[i]}
          />
        ))}
    </S.Container>
  );
};

export default Home;
