/* eslint-disable import/no-extraneous-dependencies */
import React, { createContext, useCallback, useState, useContext } from 'react';
import { jwtDecode } from 'jwt-decode'; // Importa o jwt-decode
import api from '../services/api';
import { IUser } from '../DTOS/IUser';

interface SignInCredentials {
  whatsapp: string;
  password: string;
}

interface AuthContextData {
  user: IUser;
  signIn(credentials: SignInCredentials): Promise<void>;
  signOut(): void;
  updateUser(user: IUser): void;
  isTokenExpired(): boolean; // Adiciona a função para verificar expiração do token
}

interface AuthState {
  token: string;
  user: IUser;
}

interface TokenPayload {
  exp: number; // A expiração do token é geralmente um timestamp em segundos
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = localStorage.getItem('@incentivos:token');
    const user = localStorage.getItem('@incentivos:user');

    if (token && user) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return { token, user: JSON.parse(user) };
    }

    return {} as AuthState;
  });

  const isTokenExpired = useCallback(() => {
    const { token } = data;

    if (!token) {
      return true;
    }

    try {
      const { exp } = jwtDecode<TokenPayload>(token);
      const currentTime = Date.now() / 1000;

      // Retorna true se o token já está expirado
      return exp < currentTime;
    } catch (error) {
      // Se o token não pudeSÍr ser decodificado, trata como expirado
      return true;
    }
  }, [data]);

  const signOut = useCallback(() => {
    localStorage.removeItem('@incentivos:token');
    localStorage.removeItem('@incentivos:user');

    setData({} as AuthState);
  }, []);

  const signIn = useCallback(async ({ whatsapp, password }) => {
    const response = await api.post<AuthState>('user/sessions', {
      whatsapp,
      password,
    });

    const { token, user } = response.data;

    localStorage.setItem('@incentivos:token', token);
    localStorage.setItem('@incentivos:user', JSON.stringify(user));

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token, user });
  }, []);

  const updateUser = useCallback(
    (user: IUser) => {
      localStorage.setItem('@incentivos:user', JSON.stringify(user));
      setData({
        token: data.token,
        user,
      });
    },
    [setData, data.token],
  );

  return (
    <AuthContext.Provider
      value={{ user: data.user, signIn, signOut, updateUser, isTokenExpired }}
    >
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within as AuthProvider');
  }

  return context;
}

export { AuthProvider, useAuth };
