import React from 'react';
import copy from 'copy-to-clipboard';
import * as S from './styles';

type IProps = {
  code: string;
  remaining: number;
};

const IndicationBanner: React.FC<IProps> = ({ code, remaining }) => {
  return (
    <S.Container>
      {`Você ainda pode convidar ${remaining} ${
        remaining === 1 ? 'amigo' : 'amigos'
      } com seu código de indicação: `}
      <S.ButtonCode onClick={() => copy(code)}>{code}</S.ButtonCode>
    </S.Container>
  );
};

export default IndicationBanner;
