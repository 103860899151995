import React, { useCallback, useEffect, useState } from 'react';
import { FiMessageCircle, FiShoppingBag } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { IoTicketOutline } from 'react-icons/io5';
import * as S from './styles';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import api from '../../services/api';
import { IProduct } from '../../DTOS/IProduct';

const routes = [
  {
    path: '/',
    icon: FiShoppingBag,
  },
  {
    path: '/cupons',
    icon: IoTicketOutline,
  },
];

const Header: React.FC = () => {
  const history = useHistory();
  const { isTokenExpired, user } = useAuth();
  const { addToast } = useToast();

  const [routeActive, setRouteActive] = useState('');
  const [productsPendings, setProductsPendings] = useState<IProduct[]>([]);

  const getProductPending = useCallback(async () => {
    try {
      const response = await api.get(`product/pendings/user/${user.id}`);
      setProductsPendings(response.data);
    } catch (err) {
      addToast({
        title: 'Não foi possível buscar produtos pendentes.',
        type: 'error',
      });
    }
  }, [addToast, user.id]);

  const handleLogout = useCallback(() => {
    localStorage.clear();
    window.location.reload();
  }, []);

  useEffect(() => {
    getProductPending();
    setRouteActive(history.location.pathname);
  }, [getProductPending, history.location.pathname]);

  const handleNavigate = useCallback(
    (path: string) => {
      setRouteActive(path);
      history.push(path);
    },
    [history],
  );

  useEffect(() => {
    const sessionExpired = isTokenExpired();
    if (sessionExpired) {
      addToast({
        title: 'Você deve entrar na sua conta novamente!',
      });

      handleLogout();
    }
  }, [addToast, handleLogout, isTokenExpired]);

  const handleNavigateChat = useCallback(
    async (productId: string) => {
      localStorage.setItem('@incentivos:chat', productId);
      history.push(`/chat`);
      setRouteActive(productId);
    },
    [history],
  );

  useEffect(() => {
    const chatActive = localStorage.getItem('@incentivos:chat');

    setRouteActive(oldValue =>
      oldValue === '/chat' && chatActive ? chatActive : oldValue,
    );
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.TopContent>
          {routes.map(r => (
            <S.IconContainer
              onClick={() => handleNavigate(r.path)}
              key={r.path}
              active={routeActive === r.path}
            >
              <r.icon size={20} />
            </S.IconContainer>
          ))}

          {productsPendings.length > 0 &&
            productsPendings.map((p, i) => (
              <S.IconContainer
                key={p.id}
                onClick={() => handleNavigateChat(p.id)}
                active={routeActive === p.id}
              >
                <S.Index active={routeActive.indexOf(p.id) > 0}>
                  {i + 1}
                </S.Index>
                <FiMessageCircle size={20} />
              </S.IconContainer>
            ))}
        </S.TopContent>

        <S.BottomContent>
          <S.IconContainer onClick={() => handleLogout()} active={false}>
            <S.Logout size={20} />
          </S.IconContainer>
        </S.BottomContent>
      </S.Content>
    </S.Container>
  );
};

export default Header;
