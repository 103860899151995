import styled, { css, keyframes } from 'styled-components';

const fadeInUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;

  max-height: 100vh;

  flex-wrap: wrap;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  padding: 20px;
`;

export const ChatInfo = styled.span`
  font-size: 12px;
  color: #202020;
  text-align: center;

  margin-top: 10px;
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;

  flex-direction: column;

  top: 0;
  left: 0;

  width: 100%;

  background-color: #fff;
  border-bottom: 1px solid #00000030;
  color: #000;

  font-weight: 700;
  font-size: 20px;

  padding-top: 20px;

  z-index: 1;
`;

export const Description = styled.div`
  position: relative;
  font-size: 14px;
  color: #15151590;
  z-index: 10;

  padding: 0 40px 30px;

  max-width: 500px;
  text-align: center;

  font-weight: 500;
`;

export const Chat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  flex: 1;
  max-height: 100%;

  width: 100%;
`;

export const Messages = styled.div`
  display: flex;

  flex-direction: column;
  margin-top: auto;

  overflow-y: auto; /* Use 'auto' para ativar o scroll apenas quando necessário */

  gap: 8px;
  height: max-content;

  padding: 8px;
  scroll-behavior: smooth;

  /* Estilizando a barra de rolagem */
  ::-webkit-scrollbar {
    width: 6px; /* Largura da barra de rolagem */
  }

  ::-webkit-scrollbar-track {
    background: transparent; /* Cor do fundo do track (pode deixar transparente) */
  }

  ::-webkit-scrollbar-thumb {
    background-color: black; /* Cor da barra de rolagem */
    border-radius: 10px; /* Bordas arredondadas */
  }

  ::-webkit-scrollbar-thumb:hover {
    background-color: #555; /* Cor da barra de rolagem ao passar o mouse */
  }

  /* Estilizações para Firefox */
  scrollbar-width: thin; /* Deixa a barra fina */
  scrollbar-color: black transparent; /* Cor da barra e do track no Firefox */
`;

export const Message = styled.span<{ fromUser: boolean }>`
  padding: 8px 14px;
  border-radius: 10px;

  width: fit-content;
  max-width: 80%;

  animation: ${fadeInUp} 0.2s ease-out;

  background-color: #151515;
  color: #fff;

  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

  font-size: 14px;
  font-weight: 500;

  align-self: flex-end;

  ${props =>
    props.fromUser &&
    css`
      background-color: #fff;
      color: #151515;
      align-self: flex-start;
    `}

  img {
    width: 100%;

    border-radius: 10px;
    margin: 6px 0;

    object-fit: cover;
  }
`;

export const TalkBar = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  gap: 6px;
`;

export const Suggestions = styled.div`
  display: flex;
  gap: 6px;
  width: 100%;
`;

export const Suggestion = styled.div`
  padding: 8px 16px;

  font-size: 14px;
  font-weight: 600;

  border-radius: 10px;

  background: linear-gradient(to right, #01b71b, #34db57);

  transition: 0.2s ease;
`;

export const InputContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  gap: 10px;

  width: 100%;

  border: 1px solid transparent;
  border-radius: 12px;

  padding: 4px 14px;

  font-size: 14px;

  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;

  background-color: #fff;
`;

export const Input = styled.input`
  width: 100%;
  height: 42px;

  border: 1px solid transparent;
  background-color: transparent;
  font-size: 14px;

  ::placeholder {
    color: #00000040;
  }
`;

export const Actions = styled.div``;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: right;

  height: 30px;

  border: none;
  background-color: transparent;
  color: var(--primary);

  :disabled {
    color: #00000030;
  }
`;
