import React from 'react';
import {
  Route as ReactDOMRoute,
  RouteProps as ReactDOMRouteProps,
} from 'react-router-dom';

import { useAuth } from '../hooks/auth';
import Sign from '../pages/Sign';
import PageContainer from '../components/PageContainer';
import Header from '../components/Header';
import * as S from './styles';

interface RouteProps extends ReactDOMRouteProps {
  component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({ component: Component, ...rest }) => {
  const { user } = useAuth();

  return (
    <ReactDOMRoute
      {...rest}
      render={() => {
        if (user) {
          return (
            <PageContainer>
              <Header />

              <S.Section>
                <Component />
              </S.Section>
            </PageContainer>
          );
        }
        return <Sign />;
      }}
    />
  );
};

export default Route;
