export function formatDate(date: Date): string {
  const dataISO = date;
  const data = new Date(dataISO);

  const dia = String(data.getDate()).padStart(2, '0');
  const mes = String(data.getMonth() + 1).padStart(2, '0'); // Meses em JavaScript são de 0 a 11
  const hora = String(data.getHours()).padStart(2, '0');
  const minutos = String(data.getMinutes()).padStart(2, '0');

  const dataFormatada = `dia ${dia}/${mes} às ${hora}:${minutos}`;

  return dataFormatada;
}
