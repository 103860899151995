/* eslint-disable no-nested-ternary */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiSend } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { setInterval } from 'timers';
import * as S from './styles';
import api from '../../services/api';
import { IProduct } from '../../DTOS/IProduct';
import { IMessage } from '../../DTOS/IMessage';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import MiniCoupon from '../../components/MiniCoupon';
import { formatDate } from '../../utils/formatDate';

// Chat Component
const Chat: React.FC = () => {
  const { push } = useHistory();
  const { addToast } = useToast();
  const { user } = useAuth();

  const messagesEndRef = useRef<HTMLDivElement | null>(null);

  // State management
  const [messages, setMessages] = useState<IMessage[]>([]);
  const [stage, setStage] = useState(0);
  const [inputValue, setInputValue] = useState('');
  const [product, setProduct] = useState<IProduct>();

  // Form data
  const [fullName, setFullName] = useState('Nome');
  const [address, setAddress] = useState('Endereço');
  const [storeName, setStoreName] = useState('Nome da loja');
  const [descriptionOrder, setDescriptionOrder] = useState(
    'Descrição do pedido',
  );
  const [paymentMethod, setPaymentMethod] = useState('Pagamento');

  // Refs to handle input focus
  const storeNameRef = useRef<HTMLInputElement>(null);
  const descriptionOrderRef = useRef<HTMLInputElement>(null);
  const fullNameRef = useRef<HTMLInputElement>(null);
  const addressRef = useRef<HTMLInputElement>(null);
  const paymentMethodRef = useRef<HTMLInputElement>(null);

  const scrollToBottom = useCallback(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollTop = messagesEndRef.current.scrollHeight;
    }
  }, []);

  const refreshMessages = useCallback(async () => {
    const id = localStorage.getItem('@incentivos:chat');

    const response = await api.get<IMessage[]>(`product/messages/${id}`);
    setMessages(response.data);

    if (!product || response.data.length > 0) return;

    if (product.agentId) {
      await api.post('product/message', {
        product_id: id,
        sender_id: product.agentId,
        content: 'Olá, vamos fazer seu pedido?',
      });

      await api.post('product/message', {
        product_id: id,
        sender_id: product.agentId,
        content: `No cupom de R$ ${
          product.payment.value
        }, você precisa montar um pedido mínimo de R$ ${
          product.payment.value + 15
        } (Sem contar com a taxa de entrega).`,
      });

      await api.post('product/message', {
        product_id: id,
        sender_id: product.agentId,
        content:
          'Para fazer seu pedido, responda as perguntas de verde em sua tela.',
      });
    }
  }, [product]);

  useEffect(() => {
    refreshMessages();
    const intervalId = setInterval(refreshMessages, 2500);
    return () => clearInterval(intervalId);
  }, [refreshMessages]);

  const getProduct = useCallback(async () => {
    const id = localStorage.getItem('@incentivos:chat');

    if (!id) {
      push('/');
      return;
    }

    const response = await api.get<IProduct>(`product/${id}`);
    if (!response.data || response.data.payment.status !== 'approved') {
      push('/');
    }
    setProduct(response.data);
  }, [push]);

  useEffect(() => {
    getProduct();
  }, [getProduct]);

  const handleSentMessage = useCallback(
    async (message: string) => {
      const id = localStorage.getItem('@incentivos:chat');

      if (!message) return;

      try {
        await api.post('product/message', {
          product_id: id,
          sender_id: user.id,
          content: message,
        });
        refreshMessages();
        setTimeout(scrollToBottom, 200);
        setInputValue('');
      } catch {
        addToast({
          title: 'Não foi possível enviar mensagem',
          type: 'error',
        });
      }
    },
    [addToast, refreshMessages, scrollToBottom, user.id],
  );

  const handleAdvanceStage = useCallback(
    async (nextStage: number, message: string) => {
      try {
        await handleSentMessage(message);
        if (!product) return;

        // Atualizar a ordem do produto com base no estágio atual
        const updatedOrder = {
          ...product.order,
          ...(nextStage === 1
            ? { storeName: message }
            : nextStage === 2
            ? { description: message }
            : nextStage === 3
            ? { clientName: message }
            : nextStage === 4
            ? { address: message }
            : nextStage === -1 // Adicionando o estágio para pagamento
            ? { payment: message }
            : {}),
        };

        await api.put(`product/${product.id}`, { order: updatedOrder });
        setProduct({ ...product, order: updatedOrder });

        setStage(nextStage);

        // Focus the next input when advancing stages
        if (nextStage === 1 && descriptionOrderRef.current) {
          descriptionOrderRef.current.focus();
        } else if (nextStage === 2 && fullNameRef.current) {
          fullNameRef.current.focus();
        } else if (nextStage === 3 && addressRef.current) {
          addressRef.current.focus();
        } else if (nextStage === 4 && paymentMethodRef.current) {
          paymentMethodRef.current.focus();
        }
      } catch {
        addToast({ title: 'Não foi possível atualizar pedido', type: 'error' });
      }
    },
    [addToast, handleSentMessage, product],
  );

  useEffect(() => {
    if (product?.order) {
      setStage(
        product.order.payment
          ? -1
          : product.order.address
          ? 4
          : product.order.clientName
          ? 3
          : product.order.description
          ? 2
          : product.order.storeName
          ? 1
          : 0,
      );
    }
  }, [product]);

  const createdAt = useMemo(
    () => (product ? `Iniciado ${formatDate(product.createdAt)}` : ''),
    [product],
  );
  const closedAt = useMemo(
    () =>
      product && product.closedAt
        ? `Finalizado ${formatDate(product.closedAt)}`
        : '',
    [product],
  );

  return (
    <S.Container>
      {product && (
        <S.Chat>
          <S.Header>
            {user && (
              <MiniCoupon
                closedAt={product.closedAt}
                coupon={product.payment?.value}
              />
            )}
            <S.ChatInfo>
              Chat nº
              <strong>{` ${product.payment.id}`}</strong>
              <br />
              {createdAt}
            </S.ChatInfo>
          </S.Header>

          <S.Messages ref={messagesEndRef}>
            {messages.map(message => (
              <S.Message
                fromUser={message.sender_id === product.userId}
                key={message.id}
              >
                {message.imageUrl ? (
                  <img src={message.imageUrl} alt="" />
                ) : (
                  message.content
                )}
              </S.Message>
            ))}
          </S.Messages>

          {stage === 0 && (
            <TalkBar
              suggestion="Qual o nome da loja?"
              placeholder="Ex: Mc Donald's"
              value={storeName}
              inputRef={storeNameRef}
              onChange={setStoreName}
              onSend={() => handleAdvanceStage(1, storeName)}
            />
          )}

          {stage === 1 && (
            <TalkBar
              suggestion="Descreva seu pedido:"
              placeholder="Ex: 1 hamburguer de frango com acrescimo de bacon"
              value={descriptionOrder}
              inputRef={descriptionOrderRef}
              onChange={setDescriptionOrder}
              onSend={() => handleAdvanceStage(2, descriptionOrder)}
            />
          )}

          {stage === 2 && (
            <TalkBar
              suggestion="Quem vai receber?"
              placeholder="Insira o nome e o sobrenome"
              value={fullName}
              inputRef={fullNameRef}
              onChange={setFullName}
              onSend={() => handleAdvanceStage(3, fullName)}
            />
          )}

          {stage === 3 && (
            <TalkBar
              suggestion="Endereço (Rua, número, apto, bairro e cidade)"
              placeholder="Insira o endereço completo"
              value={address}
              inputRef={addressRef}
              onChange={setAddress}
              onSend={() => handleAdvanceStage(4, address)}
            />
          )}

          {stage === 4 && (
            <TalkBar
              suggestion="Como você vai pagar a diferença?"
              placeholder="Ex: Mastercard crédito"
              value={paymentMethod}
              inputRef={paymentMethodRef}
              onChange={setPaymentMethod}
              onSend={() => handleAdvanceStage(-1, paymentMethod)}
            />
          )}

          {/* Exibe o input padrão para mensagens após o estágio de pagamento */}
          {stage === -1 && !closedAt && (
            <InputBar
              value={inputValue}
              onChange={setInputValue}
              onSend={handleSentMessage}
            />
          )}

          {closedAt && (
            <S.ChatInfo style={{ margin: 20 }}>{closedAt}</S.ChatInfo>
          )}
        </S.Chat>
      )}
    </S.Container>
  );
};

// Reusable TalkBar component with ref
const TalkBar: React.FC<{
  suggestion: string;
  placeholder: string;
  value: string;
  inputRef: React.RefObject<HTMLInputElement>;
  onChange: (value: string) => void;
  onSend: () => void;
}> = ({ suggestion, placeholder, value, inputRef, onChange, onSend }) => (
  <S.TalkBar>
    <S.Suggestions>
      <S.Suggestion>{suggestion}</S.Suggestion>
    </S.Suggestions>
    <S.InputContainer>
      <S.Input
        ref={inputRef}
        onChange={e => onChange(e.target.value || placeholder)}
        placeholder={placeholder}
        onKeyDown={e => {
          if (e.key === 'Enter') onSend();
        }}
      />
      <S.Actions>
        <S.ActionButton
          onClick={onSend}
          disabled={!value || value === placeholder}
        >
          <FiSend size={20} />
        </S.ActionButton>
      </S.Actions>
    </S.InputContainer>
  </S.TalkBar>
);

// Reusable InputBar component for normal chat messages
const InputBar: React.FC<{
  value: string;
  onChange: (value: string) => void;
  onSend: (message: string) => void;
}> = ({ value, onChange, onSend }) => (
  <S.TalkBar>
    <S.InputContainer>
      <S.Input
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter') onSend(value);
        }}
        placeholder="Digite aqui..."
      />
      <S.Actions>
        <S.ActionButton onClick={() => onSend(value)} disabled={!value}>
          <FiSend size={20} />
        </S.ActionButton>
      </S.Actions>
    </S.InputContainer>
  </S.TalkBar>
);

export default Chat;
