import React from 'react';

import { Switch } from 'react-router-dom';

import Route from './Route';

import Home from '../pages/Home';
import Sign from '../pages/Sign';
import Chat from '../pages/Chat';
import Coupons from '../pages/Coupons';

const Routes: React.FC = () => (
  <Switch>
    <Route path="/cupons" component={Coupons} />
    <Route path="/sign" component={Sign} />
    <Route path="/chat" component={Chat} />
    <Route path="/" component={Home} />
  </Switch>
);

export default Routes;
