import styled, { css } from 'styled-components';

export const Container = styled.div`
  background-color: #fff;
  position: relative;

  display: flex;

  width: 350px;
  max-width: 350px;
  height: 150px;

  border-radius: 12px;

  background-size: cover;

  overflow: hidden;

  .backgroundShadow {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background-color: #00000080;

    border-radius: 12px;

    z-index: 0;
  }

  .backgroundShadowGradient {
    position: absolute;

    width: 100%;
    height: 100%;

    top: 0;
    left: 0;

    background: linear-gradient(to top, #00000090, #00000000);

    border-radius: 12px;

    z-index: 1;
  }

  .lineContainer {
    display: flex;
    align-items: center;
    justify-content: center;

    rotate: 90deg;

    gap: 6px;

    position: absolute;

    font-size: 20px;

    bottom: 73px;

    right: 78px;

    z-index: 1;

    .line {
      width: 12px;
      border-radius: 20px;
      height: 3px;
      background-color: var(--background);
      z-index: 1;
    }
  }

  .roundBottom {
    position: absolute;
    right: 100px;
    bottom: -20px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: var(--background);

    z-index: 1;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.16);
  }

  .roundTop {
    position: absolute;
    right: 100px;

    top: -20px;

    width: 40px;
    height: 40px;

    border-radius: 50%;

    background-color: var(--background);

    z-index: 1;
    box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.16);
  }
  .roundBottomSupport {
    position: absolute;
    left: -40px;
    bottom: 80px;

    border-radius: 2px;

    width: 40px;
    height: 40px;

    background-color: var(--background);

    z-index: 1;
  }

  .roundTopSupport {
    position: absolute;
    right: -40px;
    bottom: 80px;

    border-radius: 2px;

    width: 40px;
    height: 40px;

    background-color: var(--background);

    z-index: 1;
  }
`;

export const FirstContent = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: space-between;

  width: 100%;
  height: 100%;

  padding: 10px;

  z-index: 1;
`;

export const Informations = styled.div``;

export const InfoContainer = styled.div`
  display: flex;

  align-items: center;

  gap: 8px;

  overflow: hidden;

  max-width: 180px;
`;

export const InfoText = styled.span`
  font-size: 12px;

  width: 100%;
`;

export const LastContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  padding: 10px;
  z-index: 1;
  width: 50%;
`;

export const CouponSvg = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;

  cursor: pointer;

  transition: 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }
`;

export const CouponTitle = styled.div`
  font-size: 10px;
  font-weight: 700;
  margin-top: 10px;
`;

export const CouponText = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

export const Buttons = styled.div``;

export const Button = styled.button<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 12px;

  width: 130px;
  height: 30px;
  border-radius: 100px;

  border: 1px solid var(--primary);
  outline: none;
  background-color: transparent;

  color: var(--primary);

  transition: all 0.2s ease;

  position: relative;

  &:hover {
    background-color: var(--primary);

    color: #fff;
  }

  span {
    font-size: 12px;
  }

  ${props =>
    props.active &&
    css`
      width: 30px;
    `}
`;

export const FinishAnimationContainer = styled.div`
  position: absolute;
`;
