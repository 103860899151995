import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  gap: 6px;
  padding: 0 10px 20px;

  background-repeat: no-repeat;
  background-position: right;
  background-size: cover;
`;

export const Shadow = styled.div`
  position: absolute;
  background-color: #00000060;
  height: 100vh;
  width: 100vw;

  top: 0;
  left: 0;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;

  background-color: #000000;

  padding: 40px;

  border-radius: 20px;

  gap: 10px;

  width: 400px;

  z-index: 1;
`;

export const AnimationContainer = styled.div`
  width: 100%;
  height: 200px;
`;

export const Description = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;

  height: 30px;
  margin-top: 20px;
`;

export const ErrorMessage = styled.div`
  font-size: 12px;
  padding-left: 10px;
  color: var(--danger);
`;

export const Input = styled.input`
  padding: 14px;
  color: #fff;

  background-color: #00000090;

  outline: none;
  border: 1px solid #ffffff90;

  border-radius: 8px;

  &:focus {
    border: 1px solid var(--primary);
  }
`;

export const ButtonSign = styled.button`
  height: 56px;

  margin-top: 20px;

  border-radius: 100px;
  outline: none;

  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--primary);

  transition: 0.2s ease;

  &:hover {
    background-color: var(--primary60);
    color: #fff;
  }
`;

export const ButtonChangeSign = styled.button`
  width: max-content;

  margin: 0 auto;

  margin-top: 14px;

  outline: none;

  background-color: transparent;
  color: #ffffff90;

  border: none;
  border-bottom: 1px solid #ffffff90;

  padding-bottom: 8px;

  transition: 0.2s ease;

  &:hover {
    color: var(--primary);
    border-bottom: 1px solid var(--primary);
  }
`;
