import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  color: #000;
  padding: 20px;
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 20px;

  background-color: #fff;

  margin-bottom: 10px;

  border: 1px solid #00000020;
  border-radius: 10px;

  div {
    font-weight: 500;
  }
`;

export const ProductList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;
`;

export const Product = styled.a`
  display: flex;
  flex-direction: column;
  gap: 4px;
  flex: 1;
  text-decoration: none;
  color: #000;

  background-color: #fff;

  border: 1px solid #00000020;

  padding: 6px;

  border-radius: 6px;

  transition: 0.2s ease;

  &:hover {
    transform: scale(1.02);
  }
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  justify-content: space-between;

  width: 100%;

  font-size: 14px;
`;

export const Coupon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  font-size: 14px;

  span {
    font-size: 14px;
  }

  img {
    width: 20px;
    height: 20px;
  }
`;

export const ValueContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  gap: 8px;

  background-color: var(--primary20);

  color: var(--primary);

  padding: 2px 8px;

  border-radius: 4px;

  font-size: 14px;
`;

export const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;

  font-size: 12px;
  margin-top: 4px;
`;

export const Date = styled.div`
  display: flex;
  align-items: center;

  font-size: 12px;

  position: relative;

  &.pending {
    color: #ffb800;
    font-weight: 500;

    &::after {
      content: '';
      position: absolute;

      width: 5px;
      height: 5px;
      border-radius: 50%;

      left: -10px;

      background-color: #ffb800;
    }
  }
`;

export const Empty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  gap: 8px;
`;

export const Link = styled.a`
  padding: 10px 20px;
  border-radius: 10px;

  border: 1px solid var(--primary);
  background-color: var(--primary);

  color: #fff;

  text-decoration: none;
  transition: 0.2s ease;

  &:hover {
    transform: scale(1.1);
  }
`;
