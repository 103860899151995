import React, { useMemo, useState } from 'react';
import { FiCheck } from 'react-icons/fi';
import Lottie from 'react-lottie';
import * as S from './styles';
import api from '../../services/api';
import { useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { IProduct } from '../../DTOS/IProduct';
import { toMoney } from '../../utils/toMoney';
import { formatDate } from '../../utils/formatDate';
import couponSvg from '../../assets/coupon.svg';
import animationData from '../../assets/lottie/empty.json';

const Coupons: React.FC = () => {
  const { user } = useAuth();
  const { addToast } = useToast();

  const [products, setProducts] = useState<IProduct[]>([]);
  const [searched, setSearched] = useState(false);

  useMemo(async () => {
    try {
      const response = await api.get(`product/user/${user.id}`);

      setProducts(response.data);
      setSearched(true);
    } catch (err) {
      addToast({
        title: 'Não foi possivel buscar contadores do agente.',
        type: 'error',
      });
    }
  }, [addToast, user.id]);

  return (
    <S.Container>
      {products.length > 0 ? (
        <>
          <S.ProductList>
            {products.map(p => (
              <S.Product key={p.id} href={`chat/${p.id}`}>
                <S.HeaderContainer>
                  <S.Coupon>
                    <img src={couponSvg} alt="" />
                    <span>{`${toMoney(p.payment.value)} `}</span>
                  </S.Coupon>

                  {p.payment.price && (
                    <S.ValueContainer>
                      <FiCheck />
                      {`${toMoney(p.payment.price)}`}
                    </S.ValueContainer>
                  )}
                </S.HeaderContainer>

                <S.DetailsContainer>
                  <S.Date>{`Iniciado ${formatDate(p.createdAt)}`}</S.Date>

                  {p.payment.status === 'refunded' && (
                    <S.Date>{`Reembolsado ${formatDate(p.closedAt)}`}</S.Date>
                  )}

                  {p.payment.status !== 'refunded' && p.closedAt && (
                    <S.Date>{`Finalizado ${formatDate(p.closedAt)}`}</S.Date>
                  )}

                  {!p.closedAt && p.payment.status === 'approved' && (
                    <S.Date className="pending">Em andamento</S.Date>
                  )}
                </S.DetailsContainer>
              </S.Product>
            ))}
          </S.ProductList>
        </>
      ) : (
        searched && (
          <S.Empty>
            <p>Você ainda não adquiriu cupons :(</p>
            <S.Link href="/">Ir para cupons</S.Link>
            <Lottie
              options={{
                autoplay: true,
                loop: true,
                animationData,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid slice',
                },
              }}
            />
          </S.Empty>
        )
      )}
    </S.Container>
  );
};

export default Coupons;
