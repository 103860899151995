import { FiLogOut } from 'react-icons/fi';
import styled, { css } from 'styled-components';

type IconProps = {
  active: boolean;
};

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  width: 60px;

  height: 100%;
  left: 0;

  z-index: 2;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  width: 100%;
  gap: 10px;

  padding: 20px 0;

  background-color: #020202;
  height: 100%;
`;

export const IconContainer = styled.a<IconProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  position: relative;

  width: 40px;
  height: 40px;

  padding: 10px;

  border-radius: 10px;

  border: none;
  outline: none;
  background-color: transparent;
  color: #fff;

  ${props =>
    props.active &&
    css`
      background-color: var(--primary);
      color: #000;
    `};
`;

export const Index = styled.div<{ active: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;

  background-color: #000;
  color: #fff;

  width: 14px;
  height: 14px;

  font-size: 10px;
  font-weight: 700;

  border-radius: 20px;
  bottom: -2px;
  right: -4px;

  ${props =>
    props.active &&
    css`
      background-color: #000;
      color: var(--primary);
    `};
`;

export const TopContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const BottomContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
`;

export const Logout = styled(FiLogOut)`
  cursor: pointer;
  color: #fff;
`;
