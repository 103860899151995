import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  flex-wrap: wrap;

  gap: 10px;

  width: 100%;
  padding-bottom: 20px;
`;

export const SectionTitle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  gap: 20px;

  flex-direction: column;

  top: 0;
  left: 0;

  height: max-content;

  width: 100%;

  background-color: #fff;
  border-bottom: 1px solid #00000030;
  color: #000;

  font-weight: 700;
  font-size: 20px;

  padding-top: 20px;

  z-index: 1;
`;

export const Description = styled.div`
  position: relative;
  font-size: 14px;
  color: #15151590;
  z-index: 10;

  padding: 0 40px 30px;

  max-width: 500px;
  text-align: center;

  font-weight: 500;
`;
