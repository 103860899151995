import React from 'react';
import * as S from './styles';

import background from '../../assets/background.png';
import CouponSvg from '../../assets/coupon.svg';

type MiniCouponProps = {
  coupon: number;
  closedAt: Date;
};

const MiniCoupon: React.FC<MiniCouponProps> = ({ coupon, closedAt }) => {
  return (
    <S.Container style={{ backgroundImage: `url(${background})` }}>
      <div className="backgroundShadow" />
      <div className="backgroundShadowGradient" />
      <div className="backgroundShadowGradient" />

      <S.FirstContent>
        <S.Informations>
          <S.InfoContainer>
            <S.InfoText style={{ fontWeight: 700, marginBottom: 8 }}>
              Informações
            </S.InfoText>
          </S.InfoContainer>

          <S.InfoContainer>
            {closedAt ? (
              <S.InfoText>Pedido finalizado!</S.InfoText>
            ) : (
              <S.InfoText>
                {`Neste cupom, você precisa montar um pedido mínimo de R$ ${
                  coupon + 15
                }. (Sem contar com a taxa de entrega).`}
              </S.InfoText>
            )}
          </S.InfoContainer>
        </S.Informations>
      </S.FirstContent>

      <S.LastContent>
        <S.CouponSvg src={CouponSvg} />
        <S.CouponTitle>CUPOM DE</S.CouponTitle>
        <S.CouponText>{`R$ ${coupon}`}</S.CouponText>
      </S.LastContent>

      <div className="roundBottom" />
      <div className="roundBottomSupport" />
      <div className="lineContainer">
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
        <div className="line" />
      </div>
      <div className="roundTop" />
      <div className="roundTopSupport" />
    </S.Container>
  );
};

export default MiniCoupon;
